import React from "react";
import styled from "styled-components";
import Logo from "../../../assets/landing/logogamechanger.png";
import BackgroundContainer from "../../container";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ConfirmResetView: React.FC = () => {
  return (
    <BackgroundContainer>
      <Container>
        <StyledCard>
          <DrawerHeader>
            <img src={Logo} alt="Drawer Logo" />
          </DrawerHeader>
          <Title>
            <CheckCircleIcon
              style={{ marginRight: "16px", color: "#99CC00" }}
            />
            Tu contraseña ha sido restablecida
          </Title>
          <Subtitle>Ya puede iniciar sesion en tu app</Subtitle>
        </StyledCard>
      </Container>
    </BackgroundContainer>
  );
};

const DrawerHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  color: #fff;
  margin-top: 55px;
  margin-bottom: 55px;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 24px;
  color: #fff;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 100%;
  box-sizing: border-box;
`;

const StyledCard = styled.div`
  width: 100%;
  max-width: 450px;
  height: 50vh;
  max-height: 550px;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }

  .MuiCardContent-root {
    position: relative;
    z-index: 3;
  }
`;

export default ConfirmResetView;
