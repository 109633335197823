import React from "react";
import ResetPassword from "../../ResetPassword";
import styled from "styled-components";
import BackgroundContainer from "../../container";

export type ResetPasswordViewProps = {};

const ResetPasswordView: React.FC<ResetPasswordViewProps> = () => {
  return (
    <BackgroundContainer>
      <Container>
        <StyledCard>
          <ResetPassword />
        </StyledCard>
      </Container>
    </BackgroundContainer>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
`;

const StyledCard = styled.div`
  width: 100%;
  max-width: 450px;
  height: 80vh;
  max-height: 550px;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }

  .MuiCardContent-root {
    position: relative;
    z-index: 3;
  }
`;

export default ResetPasswordView;
