import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import styled from "styled-components";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  validatePassword,
  validateConfirmPassword,
} from "../../helpers/validation";
import { Interceptor } from "../../interceptors";
import Logo from "../../assets/landing/logogamechanger.png";
import Swal from "sweetalert2";

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Label = styled.text`
  color: white;
  font-size: 25px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: #4b4e5a;
    border-radius: 7px;
  }
  .MuiInputBase-input::placeholder {
    color: white;
    opacity: 1;
    font-size: 14px;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
`;

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const resetCode = searchParams.get("code");
  const userId = searchParams.get("id");
  if (!resetCode || !userId) {
    Swal.fire({
      title: "Error!",
      text: "Ocurrió un error al recuperar la contraseña, intente más tarde!",
      icon: "error",
    });
  }
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    userId: userId,
    resetPasswordCode: resetCode,
    showPassword: false,
    showConfirmPassword: false,
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange =
    (prop: keyof typeof values) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      setErrors({ ...errors, [prop]: "" });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const validate = () => {
    let valid = true;
    let newErrors = { password: "", confirmPassword: "" };

    newErrors.password = validatePassword(values.password);
    newErrors.confirmPassword = validateConfirmPassword(
      values.password,
      values.confirmPassword
    );

    if (newErrors.password || newErrors.confirmPassword) {
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const fireError = () => {
    Swal.fire({
      title: "Error!",
      text: "Ocurrió un error al recuperar la contraseña, intente más tarde!",
      icon: "error",
    });
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      const requestBody = {
        password: values.password,
        confirmPassword: values.confirmPassword,
        resetPasswordCode: values.resetPasswordCode,
      };

      Interceptor.patch<string>(
        `/users/${values.userId}/reset-password`,
        requestBody
      )
        .then((response: AxiosResponse<string>) => {
          navigate("/success-reset");
        })
        .catch((error: AxiosError) => {
          fireError();
        });
    }
  };

  return (
    <>
      <DrawerHeader>
        <img src={Logo} alt="Drawer Logo" />
      </DrawerHeader>
      <Title>
        <Label>Elige tu nueva contraseña</Label>
      </Title>
      <LoginForm onSubmit={handleLogin}>
        <StyledTextField
          hiddenLabel
          placeholder="Nueva contraseña"
          variant="outlined"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          fullWidth
          size="small"
          error={!!errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: "#FFFFFF" }}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <StyledTextField
          hiddenLabel
          placeholder="Confirmar nueva contraseña"
          variant="outlined"
          type={values.showConfirmPassword ? "text" : "password"}
          value={values.confirmPassword}
          onChange={handleChange("confirmPassword")}
          fullWidth
          size="small"
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: "#FFFFFF" }}
                >
                  {values.showConfirmPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          style={{
            backgroundColor: "#FF6600",
            color: "white",
            borderRadius: 20,
            marginTop: 50,
            textTransform: "none",
          }}
          type="submit"
          fullWidth
        >
          Restablecer contraseña
        </Button>
      </LoginForm>
    </>
  );
};

export default ResetPassword;
