import React, { ReactNode } from "react";
import styled from "styled-components";
import BackgroundImage from "../../assets/landing/RP-Background.png";

interface BackgroundContainerProps {
  children: ReactNode;
}

const BackgroundContainer: React.FC<BackgroundContainerProps> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};

export default BackgroundContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(
      0deg,
      rgba(28, 31, 40, 0.8),
      rgba(16, 16, 23, 0.8)
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-blend-mode: overlay;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
`;
